import { useEffect, useState } from 'react';
import { Dropdown, DropdownOption as SwanDropdownOption } from '@vp/swan';
import {
    Currency,
    Price,
    bugtracker,
    categoryBriefMap,
    useIdentityContext,
} from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';
import { sendProductSelectedTrackingEvent } from '@99designs/tracking';
import { useCart } from '../../../../../lib/hooks/useCart';
import { useBriefContext } from '../../../../BriefContext/BriefContext';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { ClientConfiguredFieldProps } from '../types';
import { useServiceProductDetailsLazyQuery, useTopProductsQuery } from './products.generated';
import useUpdateProductDropdown from './useUpdateProductDropdown';
import {
    Product,
    RecentlySelectedProduct,
    mapCategoriesToProducts,
    transformBriefProductToProduct,
    transformProductToBriefProduct,
} from './utils';

export function Input({
    id,
    clientConfiguredValue,
    setValue,
    register,
}: ClientConfiguredFieldProps) {
    const { briefId } = useBriefFormContext();
    const { accessToken, shopperId, isSignedIn } = useIdentityContext();
    const { locale, pageData } = usePage();
    const { isInCart } = useCart(locale, { accessToken, shopperId });
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const updateMutation = useUpdateProductDropdown(id, briefId, clientConfiguredValue);
    const [getServiceProductDetails] = useServiceProductDetailsLazyQuery({ errorPolicy: 'all' });

    useEffect(() => {
        async function getCartData() {
            const briefInCart = await isInCart(briefId as string);
            setIsDisabled(briefInCart);
        }
        if (isSignedIn) {
            getCartData();
        }
    }, [briefId, isInCart, isSignedIn]);

    const [recentlySelectedProducts, setRecentlySelectedProducts] = useState<
        RecentlySelectedProduct[]
    >([]);
    const findRecentlySelectedProductByMpvId = (mpvId: string) => {
        return recentlySelectedProducts.find(
            (product: RecentlySelectedProduct) => product.mpvId === mpvId
        );
    };

    const { data } = useTopProductsQuery();
    const { updateProduct, product: productCardProduct } = useBriefContext();

    const isRecentlySelectedProduct = findRecentlySelectedProductByMpvId(
        productCardProduct?.fulfilmentMpvId as string
    );

    useEffect(() => {
        if (!isRecentlySelectedProduct && productCardProduct) {
            const product = transformBriefProductToProduct(productCardProduct);

            setRecentlySelectedProducts([...recentlySelectedProducts, product]);
        }
    }, [recentlySelectedProducts, productCardProduct, isRecentlySelectedProduct]);

    if (!data) {
        return null;
    }

    const [sortedCategoryMap, productMap] = mapCategoriesToProducts(data);

    const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const target = event.target.value;

        const selectedProduct = productMap.get(target)
            ? productMap.get(target)
            : findRecentlySelectedProductByMpvId(target);

        const { data: productDetails, error } = await getServiceProductDetails({
            variables: {
                mpvId: target,
            },
        });

        if (error)
            bugtracker.notify(
                new Error(
                    `Product Details unable to be retrieved. Error message: ${error.message}`
                ),
                (event) => {
                    event.addMetadata('debug info', 'mpvID', target);
                }
            );

        const updatedProduct = transformProductToBriefProduct(
            selectedProduct,
            productDetails?.serviceDetailPage.inclusions
        );

        if (updatedProduct) {
            await updateMutation(target);
            updateProduct(updatedProduct);
            sendProductSelectedTrackingEvent({
                ...pageData,
                category: 'Design Services',
            });
        }

        setValue(id, target && target.toLowerCase());
    };

    return (
        <Dropdown
            fullWidth
            defaultValue={clientConfiguredValue && clientConfiguredValue.toLowerCase()}
            {...register(id, {
                required: __('This field is required'),
                onChange: handleChange,
                disabled: isDisabled,
            })}
            data-testid="product-dropdown"
        >
            <SwanDropdownOption key={`${id}-empty`} value="" hidden>
                {__('Select product')}
            </SwanDropdownOption>
            {Array.from(sortedCategoryMap.keys()).map((category) => (
                <optgroup key={category} label={categoryBriefMap(category) || category}>
                    {sortedCategoryMap.get(category).map((product: Product, index: number) => (
                        <DropdownOption product={product} index={index} key={index} />
                    ))}
                </optgroup>
            ))}
            <optgroup label={__('Recently Selected')}>
                {recentlySelectedProducts.map((product: RecentlySelectedProduct, index: number) => (
                    <DropdownOption product={product} index={index} key={index} />
                ))}
            </optgroup>
        </Dropdown>
    );
}

const DropdownOption = ({
    product,
    index,
}: {
    product: Product | RecentlySelectedProduct;
    index: number;
}) => {
    return (
        <SwanDropdownOption key={`${product.mpvId}-${index}`} value={product.mpvId.toLowerCase()}>
            {product.name}
            {` - `}
            <Price
                amount={product.pricing.amountInCents}
                currency={product.pricing.currency as Currency}
            />
        </SwanDropdownOption>
    );
};
