import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StandardForm } from '@vp/swan';
import { MultiStep } from '@99designs/swan-wizard';
import { StepLayout } from '@99designs/swan-wizard';
import { usePage } from '@99designs/tracking';
import { BriefFormProvider } from '../BriefContext/BriefFormContext';
import { BriefFormProps, FormInput } from './BriefForm';
import { FieldGroup, fieldGroups } from './FieldGroup';
import { FieldProcessingProvider } from './FieldProcessingProvider';
import { IntroStep } from './IntroStep';
import { SubmitBriefButton } from './SubmitBriefButton';
import { getSubmit } from './getSubmit';
import { useRegisterWithTracking } from './useRegisterWithTracking';

function useIsStepValid(
    groups: ReturnType<typeof fieldGroups>,
    formErrors: { [key: string]: unknown },
    getValues: (id: string) => string,
    trigger: (name?: string | string[]) => Promise<boolean>
) {
    return useCallback(
        async (currentStep: number) => {
            // We subtract 1 from the current step because the intro step is not a form group

            const group = groups[currentStep - 1];
            if (!group) {
                return true;
            }

            return await trigger(group.fields.map((f) => f.id));
        },
        [groups, trigger, formErrors, getValues]
    );
}

const HiddenSteps = ({ steps }: { steps: JSX.Element[] }) => {
    return <div style={{ display: 'none' }}>{steps.map((s) => s)}</div>;
};

const hiddenGroupIds = ['chooseYourPhysicalProduct'];

export const MultiStepBriefForm: React.FC<BriefFormProps> = ({
    brief,
    serverValidationErrors,
    loading,
    locale,
    onSubmit,
}) => {
    const groups = fieldGroups(brief).filter(
        (g) => g.fields.length > 0 && !hiddenGroupIds.includes(g.id)
    );
    const hiddenGroups = fieldGroups(brief).filter((g) => hiddenGroupIds.includes(g.id));
    const { pageData } = usePage();

    const methods = useForm<FormInput>();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        setError,
        formState: { errors: clientErrors, dirtyFields },
        getValues,
        trigger,
    } = methods;

    const formErrors =
        Object.keys(serverValidationErrors).length > 0 ? serverValidationErrors : clientErrors;

    const registerWithTracking = useRegisterWithTracking(
        brief,
        register,
        getValues,
        dirtyFields,
        pageData
    );

    const steps = [
        <IntroStep key="intro" product={brief.product} />,
        ...groups.map((g, index) => (
            <StepLayout
                key={g.id}
                NextAction={
                    index === groups.length - 1
                        ? () => (
                              <SubmitBriefButton
                                  briefId={brief.id}
                                  fulfilmentStrategy={brief.fulfilmentStrategy}
                                  locale={locale}
                                  loading={loading}
                              />
                          )
                        : undefined
                }
            >
                <FieldGroup
                    key={g.id}
                    group={g}
                    register={registerWithTracking}
                    errors={formErrors}
                    setValue={setValue}
                    watch={watch}
                    clearErrors={clearErrors}
                    setError={setError}
                />
            </StepLayout>
        )),
    ];

    const validate = useIsStepValid(groups, formErrors, getValues, trigger);

    if (brief.groups === undefined || !brief.product?.key) {
        return null;
    }

    return (
        <StandardForm
            style={{ height: '100%' }}
            onSubmit={getSubmit(handleSubmit, onSubmit, brief.id)}
        >
            <FieldProcessingProvider>
                <BriefFormProvider briefId={brief.id}>
                    <FormProvider {...methods}>
                        {/* Until we remove the update brief mutation, we need to render fields that we don't want to show so that their values are not overridden in the update brief mutation */}
                        <HiddenSteps
                            key="hidden"
                            steps={hiddenGroups.map((g) => (
                                <FieldGroup
                                    key={g.id}
                                    group={g}
                                    register={registerWithTracking}
                                    errors={formErrors}
                                    setValue={setValue}
                                    watch={watch}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                />
                            ))}
                        />
                        <MultiStep steps={steps} validate={validate} title={brief.product.title} />
                    </FormProvider>
                </BriefFormProvider>
            </FieldProcessingProvider>
        </StandardForm>
    );
};
