import React, { useEffect, useState } from 'react';
import { Button } from '@vp/swan';
import { useIdentityContext } from '@99designs/design-services-common';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useCart } from '../../lib/hooks';
import { useFieldProcessing } from './FieldProcessingProvider';

export const addToCartEventTracking = {
    label: 'Design Services Added',
    eventDetail: '/design/briefs/create;/c;BriefForm;AddToCart-CTA',
    navigationDetail: 'AddToCart-CTA',
    category: 'Product Added',
};

function useSubmitBriefCTACopy(
    locale: string,
    briefId: string,
    fulfilmentStrategy?: FulfilmentStrategy | null
) {
    const { accessToken, shopperId, isSignedIn } = useIdentityContext();
    const { isInCart } = useCart(locale, { accessToken, shopperId });
    const [CTAcopy, setCTAcopy] = useState(
        fulfilmentStrategy === 'FULFILMENT_STRATEGY_CARE_EXPRESS' ? __('Submit') : __('Add to cart')
    );

    useEffect(() => {
        async function getCartData() {
            const briefInCart = await isInCart(briefId);
            setCTAcopy(briefInCart ? __('Submit Changes') : __('Add to cart'));
        }
        if (isSignedIn && fulfilmentStrategy !== 'FULFILMENT_STRATEGY_CARE_EXPRESS') {
            getCartData();
        }
    }, [briefId, isInCart, isSignedIn, fulfilmentStrategy]);

    return CTAcopy;
}

export const SubmitBriefButton = ({
    briefId,
    locale,
    loading,
    fulfilmentStrategy,
}: {
    briefId: string;
    locale: string;
    loading: boolean;
    fulfilmentStrategy?: FulfilmentStrategy | null;
}) => {
    const CTAcopy = useSubmitBriefCTACopy(locale, briefId, fulfilmentStrategy);
    const { processingFields } = useFieldProcessing();
    const isProcessing = processingFields.length > 0;
    return (
        <Button
            data-section={addToCartEventTracking.label}
            data-position={0}
            data-translation={addToCartEventTracking.navigationDetail}
            skin="primary"
            type="submit"
            disabled={loading || isProcessing}
        >
            {CTAcopy}
        </Button>
    );
};
