import { useCallback } from 'react';
import { buildError } from '@99designs/design-services-common';
import { useAutoFulfillmentMutation } from './autoFulfil.generated';
import { CartBody, FulfilThroughRequestsData } from './useCart';

type OrderItemTestingConfiguration = 'doNotProduce' | 'doNotShip' | 'doNotTransmit' | 'ship';

type OrderPayload = {
    createdDate?: string;
    fulfillmentGroup: FulfillmentGroup;
    merchantCustomerId?: string;
    merchantId?: string;
    merchantOrderId?: string;
    orderId?: string;
};

type FulfillmentGroup = {
    items?: OrderItem[];
};

type OrderItem = {
    fulfillmentAttributes?: FulfillmentAttribute[];
    itemId?: string;
    shortItemId?: string;
    merchantItemId?: string;
    testingConfiguration?: OrderItemTestingConfiguration;
};

type FulfillmentAttribute = {
    name?: string;
    value?: string;
};

function uuid() {
    return `${Math.random().toString(36).substr(2, 9)}-${Date.now().toString(36)}`;
}

export function useAutoFulfillment() {
    const [autoFulfillment] = useAutoFulfillmentMutation();
    return useCallback(async (cartBody: CartBody, shopperId: string) => {
        const body: OrderPayload = {
            createdDate: new Date().toISOString(),
            merchantCustomerId: shopperId,
            merchantOrderId: uuid(),
            orderId: uuid(),
            fulfillmentGroup: {
                items: [
                    {
                        fulfillmentAttributes: Object.entries(
                            cartBody.customOrderData.fulfillment
                        ).map(([key, value]) => ({
                            name: key,
                            value: String(value),
                        })),
                        itemId: uuid(),
                        shortItemId: uuid(),
                        merchantItemId: uuid(),
                    },
                ],
            },
        };
        try {
            const response = await autoFulfillment({
                variables: {
                    requestBody: JSON.stringify(body),
                },
            });

            if (response.errors || !response.data?.autoFulfillment) {
                throw new Error('Failed to auto-fulfill request');
            }
            window.location.replace(
                `http://localhost:3001/design/collaboration/${
                    (cartBody.customOrderData.fulfillment as FulfilThroughRequestsData)
                        .expertRequestID
                }`
            );
        } catch (e) {
            throw buildError(e, 'Failed to upsert cart');
        }
    }, []);
}
