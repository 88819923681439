import { useEffect, useState } from 'react';
import { ToggleSwitch } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { RadioChoiceFieldProps } from './RadioChoiceField';
import { useUpdateRadioChoiceField } from './useUpdateRadioChoiceField';

const DEFAULT_VALUE = 'YES';

export function ToggleRadioChoiceField({
    id,
    register,
    required,
    setValue,
    choiceValue,
}: RadioChoiceFieldProps) {
    const { briefId } = useBriefFormContext();
    const [selectedValue, setSelectedValue] = useState<string>(choiceValue || DEFAULT_VALUE);
    const updateMutation = useUpdateRadioChoiceField(id, briefId, selectedValue);

    useEffect(() => {
        // Because react-hook-form does not know how to handle the SWAN `ToggleSwitch` component,
        // we must manually set a default value otherwise the form will error when this field is required.
        setValue(id, selectedValue);

        if (selectedValue) {
            updateMutation(selectedValue);
        }
    }, [id, setValue, selectedValue, updateMutation]);

    return (
        <ToggleSwitch
            hideText
            defaultActivated={toggleRadioChoiceValueToSwitchValue(selectedValue)}
            standardMode
            {...register(id, {
                required: required ? __('This field is required') : false,
            })}
            onRequestActivatedChange={(activated, e) => {
                e.preventDefault();
                setSelectedValue(toggleSwitchValueToRadioChoiceValue(activated));
            }}
            aria-label={id}
        />
    );
}

// Map the form element value to the schema value
function toggleSwitchValueToRadioChoiceValue(activated: boolean) {
    return activated ? 'YES' : 'NO';
}

// Map the schema value to the form element value
function toggleRadioChoiceValueToSwitchValue(value: string) {
    return value === 'YES';
}
