import { UseFormHandleSubmit } from 'react-hook-form';
import { StructuredBriefFieldsInput } from '@99designs/graph-utils/types';

export function getSubmit(
    handleSubmit: UseFormHandleSubmit<any>,
    onSubmit: (data: StructuredBriefFieldsInput, briefId: string) => void,
    briefId: string
) {
    return handleSubmit((data) => {
        // Product Options is a ClientConfiguredField that renders multiple fields
        // based on an API response. This wrangles the data into a JSON string
        // format, which is what Graphql expects.
        const productOptions = data['productOptions'];
        if (productOptions) {
            data['productOptions'] = JSON.stringify(productOptions);
        }
        const images = data['images'];
        if (images && !Array.isArray(images)) {
            // This is a workaround for a bug in the brief form where the images field is getting {} as a value
            // This seems to be because when the upload starts, react-hook-form sets the value of the field to FileList.
            // If the upload fails it's possible for setValue not to be called, leaving the field in FileList limbo.
            // In this case we can reset the field to an empty array as the upload is broken and the {} value will fail validation.
            data['images'] = [];
        }
        return onSubmit({ dynamicBrief: { ...data } }, briefId);
    });
}
