import { useMemo } from 'react';
import debounce from 'lodash.debounce';

export default function useDebouncedState<T extends (...args: never[]) => void>(
    callback: T,
    delay: number,
    maxWait?: number
) {
    return useMemo(() => {
        return debounce(callback, delay, { maxWait, leading: false, trailing: true });
    }, [callback, delay, maxWait]);
}
