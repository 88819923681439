/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type CompatibleOptionsQueryVariables = Types.Exact<{
    request: Types.CompatibleOptionsRequest;
}>;

export type CompatibleOptionsQuery = {
    __typename: 'Query';
    compatibleOptions: Array<{
        __typename: 'ProductOption';
        name: string;
        translatedName: string;
        availableOptions: Array<{
            __typename: 'ProductAvailableOption';
            key: string;
            value: string;
            disabled: boolean;
        }>;
    }>;
};

export type PrintProductOptionsQueryVariables = Types.Exact<{
    input: Types.PrintProductOptionInput;
}>;

export type PrintProductOptionsQuery = {
    __typename: 'Query';
    printProductOptions: Array<{
        __typename: 'MerchantProductOptionsConfiguration';
        name: string;
        translatedName: string;
        values: Types.Maybe<any>;
    }>;
};

export const CompatibleOptionsDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompatibleOptions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CompatibleOptionsRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compatibleOptions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'request' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'translatedName' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableOptions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'disabled' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useCompatibleOptionsQuery__
 *
 * To run a query within a React component, call `useCompatibleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompatibleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompatibleOptionsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCompatibleOptionsQuery(
    baseOptions: Apollo.QueryHookOptions<CompatibleOptionsQuery, CompatibleOptionsQueryVariables>
) {
    return Apollo.useQuery<CompatibleOptionsQuery, CompatibleOptionsQueryVariables>(
        CompatibleOptionsDocument,
        baseOptions
    );
}
export function useCompatibleOptionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CompatibleOptionsQuery,
        CompatibleOptionsQueryVariables
    >
) {
    return Apollo.useLazyQuery<CompatibleOptionsQuery, CompatibleOptionsQueryVariables>(
        CompatibleOptionsDocument,
        baseOptions
    );
}
export type CompatibleOptionsQueryHookResult = ReturnType<typeof useCompatibleOptionsQuery>;
export type CompatibleOptionsLazyQueryHookResult = ReturnType<typeof useCompatibleOptionsLazyQuery>;
export type CompatibleOptionsQueryResult = Apollo.QueryResult<
    CompatibleOptionsQuery,
    CompatibleOptionsQueryVariables
>;
export const PrintProductOptionsDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrintProductOptions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintProductOptionInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'printProductOptions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'translatedName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __usePrintProductOptionsQuery__
 *
 * To run a query within a React component, call `usePrintProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintProductOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintProductOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintProductOptionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        PrintProductOptionsQuery,
        PrintProductOptionsQueryVariables
    >
) {
    return Apollo.useQuery<PrintProductOptionsQuery, PrintProductOptionsQueryVariables>(
        PrintProductOptionsDocument,
        baseOptions
    );
}
export function usePrintProductOptionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PrintProductOptionsQuery,
        PrintProductOptionsQueryVariables
    >
) {
    return Apollo.useLazyQuery<PrintProductOptionsQuery, PrintProductOptionsQueryVariables>(
        PrintProductOptionsDocument,
        baseOptions
    );
}
export type PrintProductOptionsQueryHookResult = ReturnType<typeof usePrintProductOptionsQuery>;
export type PrintProductOptionsLazyQueryHookResult = ReturnType<
    typeof usePrintProductOptionsLazyQuery
>;
export type PrintProductOptionsQueryResult = Apollo.QueryResult<
    PrintProductOptionsQuery,
    PrintProductOptionsQueryVariables
>;
