import { useUpdateField } from '../useUpdateField';
import { useBriefSetGridChoiceMutation } from './briefSetGridChoice.generated';

export function useUpdateGridChoiceField(
    key: string,
    briefId?: string,
    initialValue?: string | null
) {
    const [setGridChoice] = useBriefSetGridChoiceMutation();
    return useUpdateField<string>({ key, setField: setGridChoice, briefId, initialValue });
}
