/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type AutoFulfillmentMutationVariables = Types.Exact<{
    requestBody: Types.Scalars['String'];
}>;

export type AutoFulfillmentMutation = { __typename: 'Mutation'; autoFulfillment: boolean };

export const AutoFulfillmentDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'AutoFulfillment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestBody' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoFulfillment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'requestBody' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'requestBody' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
};
export type AutoFulfillmentMutationFn = Apollo.MutationFunction<
    AutoFulfillmentMutation,
    AutoFulfillmentMutationVariables
>;

/**
 * __useAutoFulfillmentMutation__
 *
 * To run a mutation, you first call `useAutoFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoFulfillmentMutation, { data, loading, error }] = useAutoFulfillmentMutation({
 *   variables: {
 *      requestBody: // value for 'requestBody'
 *   },
 * });
 */
export function useAutoFulfillmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AutoFulfillmentMutation,
        AutoFulfillmentMutationVariables
    >
) {
    return Apollo.useMutation<AutoFulfillmentMutation, AutoFulfillmentMutationVariables>(
        AutoFulfillmentDocument,
        baseOptions
    );
}
export type AutoFulfillmentMutationHookResult = ReturnType<typeof useAutoFulfillmentMutation>;
export type AutoFulfillmentMutationResult = Apollo.MutationResult<AutoFulfillmentMutation>;
export type AutoFulfillmentMutationOptions = Apollo.BaseMutationOptions<
    AutoFulfillmentMutation,
    AutoFulfillmentMutationVariables
>;
