import { useUpdateField } from '../useUpdateField';
import { useBriefSetMultiGridChoiceMutation } from './briefSetMultiGridChoice.generated';

export function useUpdateMultiGridChoiceField(
    key: string,
    briefId?: string,
    initialValue?: string[]
) {
    const [setMultiGridChoice] = useBriefSetMultiGridChoiceMutation();
    return useUpdateField<string[] | string>({
        key,
        setField: setMultiGridChoice,
        briefId,
        initialValue: initialValue || [],
    });
}
