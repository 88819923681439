import { categoryBriefMap } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { NormalisedBriefProduct } from '../../../../../components/BriefContext';
import { TopProductsQuery } from './products.generated';

type Pricing = Pick<TopProductsQuery['topProducts'][0], 'pricing'>;

export type Product = TopProductsQuery['topProducts'][0]['product'] & Pricing;

export type RecentlySelectedProduct = Omit<Product, '__typename' | 'categories' | 'services'>;

const hasProductInCategoryMap = (product: Product) => (v: Product) => v.mpvId === product.mpvId;

const findProductInCategoryMap = (
    categoryMap: Map<string, [Product]>,
    categoryName: string,
    f: any
) => categoryMap.get(categoryName)?.find(f);

export const mapCategoriesToProducts = (data: TopProductsQuery) => {
    const productMap = new Map();
    const categoryMap = new Map();
    const products: Product[] = [];

    data?.topProducts
        .filter((item) => item.product.categories.some((category) => category.type === 'Brief'))
        .forEach((item) => {
            item.product.categories.forEach((category) => {
                // Asking whether this is a defined category for the brief as
                // present in the brief category map
                if (!categoryBriefMap(category.name)) {
                    return;
                }

                const categoryName = category.name;
                const product: Product = {
                    ...item.product,
                    pricing: item.pricing,
                };

                products.push(product);

                if (!categoryMap.has(categoryName)) {
                    categoryMap.set(categoryName, []);
                }

                // Check to see if product exists in categorymap before pushing,
                // prevents any possible duplication of products
                if (
                    !findProductInCategoryMap(
                        categoryMap,
                        categoryName,
                        hasProductInCategoryMap(product)
                    )
                ) {
                    categoryMap.get(categoryName).push(product);
                }

                productMap.set(product.mpvId.toLowerCase(), product);
            });
        });

    const sortedCategoryMap = sortCategoryMapByRank(products, categoryMap);

    return [sortedCategoryMap, productMap];
};

export type ServiceProductDetail = {
    description: string;
};

export const transformProductToBriefProduct = (
    product: Product,
    serviceProductDetails: ServiceProductDetail[] | undefined
): NormalisedBriefProduct | null => {
    if (!serviceProductDetails) {
        return null;
    }

    const details: Array<{ __typename: 'ProductPackageDetails'; title: string }> = [];
    serviceProductDetails.map((s) =>
        details.push({
            __typename: 'ProductPackageDetails',
            title: s.description,
        })
    );

    const { name, pricing, mpvId } = product;

    return {
        key: product.productKey,
        title: __('Custom Design: ') + name,
        details,
        pricing: {
            __typename: 'FixedPrice',
            amount: {
                __typename: 'Money',
                amountInCents: pricing.amountInCents,
                currency: pricing.currency,
            },
        },
        fulfilmentMpvId: mpvId,
    };
};

export const transformBriefProductToProduct = (
    briefProduct: NormalisedBriefProduct
): RecentlySelectedProduct => {
    return {
        productKey: briefProduct.key,
        mpvId: briefProduct.fulfilmentMpvId || '',
        name: `${briefProduct.title}`.replace(__('Custom Design:'), '').trim(),
        pricing: {
            __typename: 'Money',
            amountInCents: briefProduct.pricing.amount.amountInCents,
            currency: briefProduct.pricing.amount.currency,
        },
    };
};

export const sortCategoryMapByRank = (
    products: Product[],
    categoryMap: Map<string, Product[]>
): Map<string, Product[]> => {
    const categoriesByRank: { [category: string]: number } = {};

    products.forEach((product) => {
        product.categories.forEach((category) => {
            categoriesByRank[category.name] = category.rank;
        });
    });

    const sortedCategoryEntries = [...categoryMap.entries()].sort(
        ([categoryA], [categoryB]) => categoriesByRank[categoryA] - categoriesByRank[categoryB]
    );

    return new Map(sortedCategoryEntries);
};
