/**
 * The time to wait on last keystroke before a mutation should be sent on change, in milliseconds.
 */
export const TEXT_MUTATION_DEBOUNCE_DURATION = 1000;

/**
 * The time on change that a mutation definitely is sent, in milliseconds.
 *
 * This should be set to a large number, and is intended to prevent a lost mutation in edge cases.
 */
export const TEXT_MUTATION_DEBOUNCE_MAX_DURATION = 30000;
