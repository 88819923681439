export type PageStage = 'Discover' | 'Design';
export type PAGE_STAGES = 'design' | 'discover' | 'product-page' | 'error-404' | 'error-internal';

export const PAGE_DEPT = 'Design Services';

export const BRIEF_PAGE_NAME = 'Design Services:Expert Services Brief';
export const PAGE_TRACKING_CONFIG: Record<PAGE_STAGES, Omit<PageTracking, 'pageName'>> = {
    design: {
        pageStage: 'Design',
        pageSection: 'Design Services',
        pageDept: PAGE_DEPT,
    },
    discover: {
        pageStage: 'Discover',
        pageSection: 'Design Services',
        pageDept: PAGE_DEPT,
    },
    'product-page': {
        pageStage: 'Discover',
        pageSection: 'Product Page',
        pageDept: PAGE_DEPT,
    },
    'error-404': {
        pageStage: 'Error Page',
        pageSection: '404 Error',
        pageDept: PAGE_DEPT,
    },
    'error-internal': {
        pageStage: 'Error Page',
        pageSection: 'Internal Error',
        pageDept: PAGE_DEPT,
    },
};
