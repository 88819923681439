import { __ } from '@99designs/i18n';
import { Input as DesignLiveAppointmentV1Input } from './ClientConfiguredFields/DesignLiveAppointmentV1/DesignLiveAppointmentV1Input';
import { ProductDropdown } from './ClientConfiguredFields/ProductDropdown';
import { Input as ProductOptionsInput } from './ClientConfiguredFields/ProductOptions/ProductOptionsInput';
import { ClientConfiguredFieldProps } from './ClientConfiguredFields/types';

export function Input(props: ClientConfiguredFieldProps) {
    const { questionId } = props;

    switch (questionId) {
        case 'design-live-appointment-v1':
            return <DesignLiveAppointmentV1Input {...props} />;
        case 'design-live-appointment-v2':
            // This is the same component but the v2 schema is a conditional field
            // so hiding it does not need to be done on the front end
            return <DesignLiveAppointmentV1Input {...props} />;
        case 'care-design-physical-product-v1':
            return <ProductDropdown {...props} />;
        case 'care-design-product-options-v1':
            return <ProductOptionsInput {...props} />;
        default:
            throw new Error(`Unknown frontend managed field: ${questionId}`);
    }
}
