import {
    ChangeHandler,
    FieldNamesMarkedBoolean,
    FieldValues,
    RegisterOptions,
    UseFormGetValues,
    UseFormRegister,
} from 'react-hook-form';
import debounce from 'lodash.debounce';
import startCase from 'lodash.startcase';
import { PAGE_TRACKING_CONFIG, sendDesignBriefEditedTrackingEvent } from '@99designs/tracking';
import { BriefFormProps, FormInput } from './BriefForm';
import { TEXT_MUTATION_DEBOUNCE_DURATION } from './FormFields/text/debounce';

const fieldDebouncers = new Map<string, ReturnType<typeof debounce>>();

function getOrCreateDebouncer(fieldId: string) {
    if (!fieldDebouncers.has(fieldId)) {
        fieldDebouncers.set(
            fieldId,
            debounce(sendDesignBriefEditedTrackingEvent, TEXT_MUTATION_DEBOUNCE_DURATION)
        );
    }
    return fieldDebouncers.get(fieldId) as ReturnType<typeof debounce>;
}

/**
 * Custom hook to wrap the register function for tracking onChange events
 */
export function useRegisterWithTracking(
    brief: BriefFormProps['brief'],
    register: UseFormRegister<FormInput>,
    getValues: UseFormGetValues<FormInput>,
    dirtyFields: FieldNamesMarkedBoolean<FormInput>,
    pageData: any
) {
    /**
     * Let's wrap the register function so we can eavesdrop on the onChange
     * calls. This can be used to send some analytics.
     */
    const registerWithTracking: UseFormRegister<FieldValues> = (
        name: string,
        options?: RegisterOptions
    ) => {
        const { onChange, ...remainingValues } = register(name, options);
        const onChangeWithTracking: ChangeHandler = (event) => {
            const field = brief.dynamicFields.find((x) => x.id === name);
            const onChangeReturn = onChange(event);
            const mpvId = getValues('physicalProduct');

            /**
             * The product options have sub-field for some printed
             * products. This includes Vinyl Banners, Folded Leaflets and
             * Retractable Banners. In this case we only want to take the final
             * section from the field name.
             *
             * For example 'productOptions.Product Orientation' should become,
             * 'Product Orientation'.
             */

            const fieldName = name.split('.').slice(-1)[0];
            const label = `Design Brief:${startCase(fieldName)}`;

            if (!field) {
                sendDesignBriefEditedTrackingEvent({
                    label,
                    mpvId,
                    ...pageData,
                    ...PAGE_TRACKING_CONFIG['design'],
                });

                return onChangeReturn;
            }

            /**
             * We are checking the type of the field to decide if we want to
             * send a singluar event or many events. If any other types of text
             * based fields are added, we will need to add the type name to this
             * isTextField check.
             *
             * If we are editing a text field then we should only send one
             * event. Otherwise we will be spamming Segment with a large number
             * of events.
             *
             * Alternatively if the field is non-text we can send every event
             * since the volume won't be bad.
             */

            const isTextField = ['TextAreaField', 'InputField'].includes(field['__typename']);

            try {
                if (isTextField) {
                    const debouncedSendDesignBriefEditedTrackingEvent = getOrCreateDebouncer(name);
                    debouncedSendDesignBriefEditedTrackingEvent({
                        label,
                        mpvId,
                        ...pageData,
                        ...PAGE_TRACKING_CONFIG['design'],
                    });
                } else {
                    sendDesignBriefEditedTrackingEvent({
                        label,
                        mpvId,
                        ...pageData,
                        ...PAGE_TRACKING_CONFIG['design'],
                    });
                }
            } catch (e) {
                console.error(e);
            }

            return onChangeReturn;
        };

        return { onChange: onChangeWithTracking, ...remainingValues };
    };

    return registerWithTracking;
}
