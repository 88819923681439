export enum Currency {
    AUD = 'AUD',
    CAD = 'CAD',
    CHF = 'CHF',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    INR = 'INR',
    HKD = 'HKD',
    JPY = 'JPY',
    MXN = 'MXN',
    NOK = 'NOK',
    NZD = 'NZD',
    SEK = 'SEK',
    SGD = 'SGD',
    USD = 'USD',
}

const unitMultiplier: { [currency in Currency]: number } = {
    [Currency.AUD]: 100,
    [Currency.CAD]: 100,
    [Currency.CHF]: 100,
    [Currency.DKK]: 100,
    [Currency.EUR]: 100,
    [Currency.GBP]: 100,
    [Currency.INR]: 100,
    [Currency.HKD]: 100,
    [Currency.JPY]: 1,
    [Currency.MXN]: 100,
    [Currency.NOK]: 100,
    [Currency.NZD]: 100,
    [Currency.SEK]: 100,
    [Currency.SGD]: 100,
    [Currency.USD]: 100,
};

export const toSubUnit = (unit: number, currency: Currency): number =>
    unit * unitMultiplier[currency];

export const toUnit = (subUnit: number, currency: Currency): number =>
    subUnit / unitMultiplier[currency];
