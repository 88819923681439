import Bugsnag from '@bugsnag/js';
import { ClaimBriefMutation } from '../../graph/ClaimBrief.generated';
import { DesignServiceRequestType } from './consts';

export const getRequestType = (
    dynamicFields: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): DesignServiceRequestType => {
    const DEFAULT_REQUEST_TYPE = 'Standard';
    const field = dynamicFields.find((field) => field.id === 'requestType');

    // If the field does not exist there is no option for Design live and there is no error.
    if (!field) {
        return DEFAULT_REQUEST_TYPE;
    }
    // check all single choice select field types
    // If the field exists it should be retrievable as one of the single choice select field types.
    if (field.__typename === 'GridChoiceField') {
        return (field.choiceValue as DesignServiceRequestType) || DEFAULT_REQUEST_TYPE;
    }
    if (field.__typename === 'RadioChoiceField') {
        return (field.choiceValue as DesignServiceRequestType) || DEFAULT_REQUEST_TYPE;
    }
    if (field.__typename === 'SelectChoiceField') {
        return (field.choiceValue as DesignServiceRequestType) || DEFAULT_REQUEST_TYPE;
    }

    // If the value of requestType exists but is not retrievable, there is an error. As throwing an error will break the flow but be equally visible, we will log the error to Bugsnag.
    Bugsnag.notify(new Error(`Unknown field type for requestType: ${field.__typename}`));

    return DEFAULT_REQUEST_TYPE;
};

export function designLiveDataFromBrief(
    briefData: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): {
    designLiveAppointmentTime?: string;
    timezone?: string;
} {
    const requestType = getRequestType(briefData);
    if (requestType !== 'DesignLive') {
        return {};
    }

    for (const field of briefData) {
        switch (field.__typename) {
            case 'ClientConfiguredField':
                if (field.id === 'appointmentTime') {
                    return {
                        designLiveAppointmentTime: field.clientConfiguredValue,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    };
                }
                break;
        }
    }

    return {};
}
