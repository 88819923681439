import { useEffect, useState } from 'react';
import { AlertBox, Box, FormLabel, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { ClientConfiguredFieldProps } from '../types';
import DateSelector from './DateSelector';
import TimeSelector from './TimeSelector';
import { useUpdateDateField } from './useUpdateDateField';

// clientConfiguredValue is in the following format: '2023-08-14T00:00:00+10:00`
export function Input({
    id,
    clientConfiguredValue,
    setValue,
    register,
    immutable,
}: ClientConfiguredFieldProps) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let localIsoTime = '';
    if (clientConfiguredValue !== '') {
        // clientConfiguredValue date includes the time, and is in UTC
        // in this component, date should not include the time, and should be the local time, in the iso string format
        const dateObj = new Date(clientConfiguredValue);
        localIsoTime =
            new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000)
                .toISOString()
                .split('T')[0] + 'T00:00:00Z';
    }

    const [date, setDateDispatcher] = useState<string | null>(localIsoTime);
    const [time, setTime] = useState(clientConfiguredValue);
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateDateField(id, briefId, time);

    const setDate = (date: string | null) => {
        setDateDispatcher(date);

        setTime('');
    };

    useEffect(() => {
        setValue(id, time);
        if (time) {
            updateMutation(time);
        }
    }, [id, time, setValue, updateMutation]);

    const dateObj = new Date(time);
    return (
        <div>
            <h2>{__('Choose a day and time')}</h2>
            <FormLabel htmlFor={id} marginBottom={'3'}>
                {__('Your timezone')}
            </FormLabel>
            <Typography fontSize="small">{timezone}</Typography>
            <div {...register(id, { required: __('This field is required') })}>
                <Box marginTop="6">
                    <DateSelector
                        timezone={timezone}
                        setDate={setDate}
                        date={date}
                        defaultValue={clientConfiguredValue}
                        immutable={immutable}
                    />
                </Box>
                {date && (
                    <Box marginTop="7">
                        <TimeSelector
                            timezone={timezone}
                            time={time}
                            setTime={setTime}
                            date={date}
                            defaultValue={clientConfiguredValue}
                            immutable={immutable}
                        />
                    </Box>
                )}
            </div>
            {time && (
                <AlertBox style={{ width: '100%' }} skin="success" marginTop="7">
                    <Typography fontSkin="body-standard" fontSize="small">
                        {__('Your DesignLive session will be scheduled for')}
                    </Typography>
                    <Box mt="5">
                        <Typography fontWeight="bold" as="span">
                            {dateObj.toLocaleDateString('default', {
                                dateStyle: 'full',
                            })}
                        </Typography>
                        <Typography fontSkin="body-standard-bold" marginLeft="2" as="span">
                            {dateObj.toLocaleTimeString('default', {
                                hour: 'numeric',
                                minute: '2-digit',
                                timeZoneName: 'short',
                                hourCycle: 'h12',
                            })}
                        </Typography>
                        <Typography fontSkin="body-standard" fontSize="small" mt="6">
                            {__(`You'll be able to reschedule after payment if you need to.`)}
                        </Typography>
                    </Box>
                </AlertBox>
            )}
        </div>
    );
}
