import { useUpdateField } from '../useUpdateField';
import { useBriefSetRadioChoiceMutation } from './briefSetRadioChoice.generated';

export function useUpdateRadioChoiceField(
    key: string,
    briefId?: string,
    initialValue?: string | null
) {
    const [setRadioChoice] = useBriefSetRadioChoiceMutation();
    return useUpdateField({ key, setField: setRadioChoice, briefId, initialValue });
}
