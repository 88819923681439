import React from 'react';
import { Box, FlexBox, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';

// TODO: Copied from matching. Can we unify?

export default function FullPageSpinner() {
    return (
        <FlexBox justifyContent="center" alignItems="center" style={{ height: '100vh' }} mx={4}>
            <Box my={2}>
                <Spinner accessibleText={__('Loading...')} />
            </Box>
        </FlexBox>
    );
}
