import { Box, Card, FlexBox, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';

interface BoxedLoaderProps {
    label: string;
}

export function BoxedLoader({ label }: BoxedLoaderProps) {
    return (
        <Card bordered>
            <Box paddingTop="7" paddingBottom="7">
                <FlexBox justifyContent="center" marginBottom="6">
                    <Spinner accessibleText={__('Loading...')} />
                </FlexBox>
                <FlexBox justifyContent="center">
                    <Box>{label}</Box>
                </FlexBox>
            </Box>
        </Card>
    );
}
