import { BriefFileInput } from '@99designs/graph-utils/types';
import { useUpdateField } from '../useUpdateField';
import { useBriefSetFilesMutation } from './briefSetFiles.generated';

export function useUpdateFilesField(
    key: string,
    briefId?: string,
    initialValue?: BriefFileInput[]
) {
    const [setFiles] = useBriefSetFilesMutation();
    return useUpdateField<BriefFileInput | BriefFileInput[]>({
        key,
        setField: setFiles,
        briefId,
        initialValue: initialValue || [],
    });
}
