import { useUpdateField } from '../useUpdateField';
import { useBriefSetMultiChoiceMutation } from './briefSetMultiChoice.generated';

export function useUpdateMultiChoiceField(key: string, briefId?: string, initialValue?: string[]) {
    const [setMultiChoice] = useBriefSetMultiChoiceMutation();
    return useUpdateField<string[] | string>({
        key,
        setField: setMultiChoice,
        briefId,
        initialValue: initialValue || [],
    });
}
