import { BoundedContent, Box, Link, Typography } from '@vp/swan';
import Image from 'next/image';
import { __ } from '@99designs/i18n';
import Ghost from './Ghost.png';

export function NotFound({ useImg }: { useImg?: boolean }) {
    return (
        <BoundedContent>
            <Box marginTop={10} marginBottom={'7'} textAlign="center">
                {useImg ? (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <img alt="ghost" src={Ghost} width={128} height={128} />
                ) : (
                    <Image alt="ghost" src={Ghost} width={128} height={128} />
                )}
                <Box marginTop={8}>
                    <Typography fontSize="x3large" fontWeight="bold">
                        {__("Boo. That page didn't turn up - but we're here to help.")}
                    </Typography>
                </Box>
                <Box marginTop={8} marginBottom={10}>
                    <Link href="/">{__('Get back to our homepage')}</Link>
                </Box>
            </Box>
        </BoundedContent>
    );
}
