import { useUpdateField } from '../useUpdateField';
import { useBriefSetSelectChoiceMutation } from './briefSetSelectChoice.generated';

export function useUpdateSelectChoiceField(
    key: string,
    briefId?: string,
    initialValue?: string | null
) {
    const [setSelectChoice] = useBriefSetSelectChoiceMutation();
    return useUpdateField({ key, setField: setSelectChoice, briefId, initialValue });
}
