import { Box, Typography } from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { BoxedLoader } from '@99designs/design-services-common';
import { DateSelector as DateSelectorCommon } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';
import { useDesignLiveAvailableDatesQuery } from './appointments.generated';

interface DateSelectorProps {
    timezone: string;
    date: string | null;
    setDate: (date: string | null) => void;
    defaultValue: string;
    immutable: boolean | null;
}

export default function DateSelector({
    timezone,
    setDate,
    date,
    defaultValue,
    immutable,
}: DateSelectorProps) {
    const { locale } = usePage();

    const { data, loading, error } = useDesignLiveAvailableDatesQuery({
        variables: {
            timezone,
            locale,
        },
        onCompleted: (d) => {
            if (!defaultValue) return;

            const dates = d.setField.dates;
            const defaultDateInAvailableDates = dates
                .map((date) => ({
                    date: date.date.split('T')[0],
                    isAvailable: date.isAvailable,
                }))
                .find((dd) => dd.date === defaultValue.split('T')[0] && dd.isAvailable === true);

            if (!date && defaultDateInAvailableDates) {
                setDate(defaultValue);
            }
        },
        onError: (e) => {
            bugtracker.notify(`Failed to get available design live dates: ${e.message}`);
        },
    });

    if (error) {
        return (
            <Box marginTop="5">
                <Typography fontSize="small" textColor="error">
                    {__(
                        'There was an error fetching available dates. Please refresh the page to try again.'
                    )}
                </Typography>
            </Box>
        );
    }

    if (loading) {
        return <BoxedLoader label={__('Loading available dates...')} />;
    }

    const dates = data?.setField.dates;
    if (!dates || dates.length === 0) {
        return (
            <Typography fontSize="small">
                {__(`There are no available dates at this time. Please select "Review over email" or try
                again later.`)}
            </Typography>
        );
    }

    return (
        <DateSelectorCommon
            setDate={setDate}
            date={date}
            defaultValue={defaultValue}
            immutable={immutable}
            appointmentDates={dates.map((ad) => ({
                date: ad.date,
                isAvailable: ad.isAvailable,
            }))}
        />
    );
}
