import BugsnagPluginReact from '@bugsnag/plugin-react';
import TrackingJsModule from '@vp/react-tracking';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { ErrorBoundary } from '@vp/swan';
import { FullPageSpinner, NotFoundErrorPage, SwanConfiguration } from '@99designs/briefs';
import { getCurrentCulture } from '@99designs/design-services-common';
import {
    AuthenticatedApolloProvider,
    IdentityProvider,
    buildAuthConfiguration,
    useIdentityContext,
} from '@99designs/design-services-common';
import { AlertProvider } from '@99designs/design-services-layouts';
import { BriefProvider } from '@99designs/feature-design-services-brief';
import { PAGE_TRACKING_CONFIG, PageProvider } from '@99designs/tracking';
import i18n from '../lib/i18n';
import { Brief } from '../routes/Brief';

Bugsnag.start({
    apiKey: process.env.VITE_BRIEFS_BUGSNAG_API_KEY ?? '',
    enabledReleaseStages: ['production'],
    releaseStage: process.env.VITE_BRIEFS_BUGSNAG_RELEASE_STAGE,
    plugins: [new BugsnagPluginReact(React)],
    onError: (event) => {
        event.addMetadata('request', {
            url: event.request.url,
            headers: event.request.headers,
        });

        event.addMetadata('debug info', {
            errorLocation: typeof window === 'undefined' ? 'server' : 'client',
        });
    },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
// const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();

/**
 * The below import structure is a workaround for the fact that these packages are not built as ES
 * Modules.
 *
 * At dev-time the import is correctly importing just the default export, but at build time the
 * import is importing the entire module.
 *
 * We therefore have to use the default key to get the default export.
 *
 * See: https://github.com/vitejs/vite/issues/4209
 */
const TrackingJs = (TrackingJsModule as any).default ?? TrackingJsModule; // eslint-disable-line @typescript-eslint/no-explicit-any

function App() {
    const currentCulture = useMemo(() => getCurrentCulture(), []);

    void i18n.changeLanguage(currentCulture);

    const authConfig = useMemo(() => {
        return buildAuthConfiguration(
            process.env.VITE_BRIEFS_HOSTNAME || '',
            {
                internalAccess: 'wU1R9YyaNcvmExIicqGonvUVQGDkJMOr',
                directAccess: '4e301fd6cd21daa1423a7db2697e42b0',
            },
            /^https?:\/\/(.+\.growth\.99cluster\.com)/
        );
    }, []);

    return (
        <AlertProvider>
            <ErrorBoundary>
                <SwanConfiguration>
                    <IdentityProvider locale={currentCulture} {...authConfig}>
                        <BrowserRouter
                            basename={
                                process.env.NODE_ENV === 'production'
                                    ? '/experts/request/brief'
                                    : ''
                            }
                        >
                            <AuthenticatedApolloProvider
                                locale={currentCulture}
                                endpoint={process.env.VITE_BRIEFS_GRAPHQL_ENDPOINT || ''}
                            >
                                <TrackingJs
                                    tenant="vistaprint"
                                    culture={currentCulture}
                                    environment={process.env.VITE_BRIEFS_TRACKING_ENV || 'dev'}
                                />
                                <BriefProvider>
                                    <PageProvider
                                        locale={currentCulture}
                                        pageData={{
                                            pageName:
                                                'Design Services:Expert Services Multi-Step Brief',
                                            ...PAGE_TRACKING_CONFIG['design'],
                                        }}
                                    >
                                        <AppRoutes />
                                    </PageProvider>
                                </BriefProvider>
                            </AuthenticatedApolloProvider>
                        </BrowserRouter>
                    </IdentityProvider>
                </SwanConfiguration>
            </ErrorBoundary>
        </AlertProvider>
    );
}

function SignOut() {
    const identity = useIdentityContext();
    const navigate = useNavigate();

    useEffect(() => {
        identity.SignOut();
        navigate('/');
    });

    return <FullPageSpinner />;
}

function AppRoutes() {
    return (
        <Routes>
            <Route path=":jobId" element={<Brief />} />

            {process.env.NODE_ENV === 'development' && (
                <Route path="sign-out" element={<SignOut />}></Route>
            )}

            <Route path="*" element={<NotFoundErrorPage />} />
        </Routes>
    );
}

export default App;
