import { Time } from 'schema-dts';
import { useUpdateField } from '../../useUpdateField';
import { useBriefSetDateMutation } from './appointments.generated';

export function useUpdateDateField(key: string, briefId?: string, initialValue?: Time) {
    const [setDate] = useBriefSetDateMutation();
    return useUpdateField({ key, setField: setDate, briefId, initialValue });
}

export default useUpdateDateField;
