import React, { createContext, useContext } from 'react';

interface BriefContextProps {
    id: string;
    children?: React.ReactNode;
}

type BriefContextData = {
    id: string;
};

export const BriefContext = createContext<BriefContextData | null>(null);

export const BriefProvider = ({ children }: BriefContextProps) => {
    return (
        <BriefContext.Provider
            value={{
                id: '',
            }}
        >
            {children}
        </BriefContext.Provider>
    );
};

export const useBriefsContext = () => {
    const context = useContext(BriefContext);
    if (!context) {
        throw new Error('useBriefContext must be used within a BriefProvider');
    }
    return context;
};
