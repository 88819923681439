import { __ } from '@99designs/i18n';

export const categoryBriefMap = (category: string) =>
    ({
        SignsPosters: __('Signs & Posters'),
        LabelsStickers: __('Labels & Stickers'),
        Stationery: __('Stationery'),
        Other: __('Other'),
        BusinessCards: __('Business Cards'),
        MarketingMaterials: __('Marketing Materials'),
        PopularProducts: __('Popular Products'),
        Banners: __('Banners'),
        Clothing: __('Clothing'),
        Packaging: __('Packaging'),
        WeddingIllustrations: __('Wedding Illustrations'),
    }[category]);
