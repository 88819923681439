import { useCallback } from 'react';
import { useFieldProcessing } from '../../FieldProcessingProvider';
import { TEXT_MUTATION_DEBOUNCE_DURATION, TEXT_MUTATION_DEBOUNCE_MAX_DURATION } from './debounce';
import useDebouncedState from './useDebouncedState';

export function useDebouncedTextUpdate(id: string, updateMutation: (value: string) => void) {
    const { setProcessingField } = useFieldProcessing();

    const mutationCallback = useCallback(
        async (value: string) => {
            try {
                await updateMutation(value);
            } finally {
                setProcessingField(id, false);
            }
        },
        [id, setProcessingField, updateMutation]
    );

    return useDebouncedState(
        mutationCallback,
        TEXT_MUTATION_DEBOUNCE_DURATION,
        TEXT_MUTATION_DEBOUNCE_MAX_DURATION
    );
}
