import React from 'react';

export function useSteps(stepCount: number, trackStep = true) {
    const [currentStep, setCurrentStep] = React.useState(() => {
        if (typeof window !== 'undefined' && trackStep) {
            const params = new URLSearchParams(window.location.search);
            const stepParam = params.get('step');
            return stepParam ? Math.min(parseInt(stepParam, 10), stepCount - 1) : 0;
        }
        return 0;
    });

    React.useEffect(() => {
        if (typeof window !== 'undefined' && trackStep) {
            const url = new URL(window.location.href);
            url.searchParams.set('step', currentStep.toString());
            window.history.replaceState({}, '', url.toString());
        }
    }, [currentStep, trackStep]);

    const goToNextStep = () => {
        if (currentStep < stepCount - 1) {
            setCurrentStep((currentStep) => currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep((currentStep) => currentStep - 1);
        }
    };

    return { currentStep, goToNextStep, goToPreviousStep };
}
