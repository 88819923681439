import { useUpdateField } from '../useUpdateField';
import { useBriefSetTextAreaMutation, useBriefSetTextMutation } from './briefSetText.generated';

export function useUpdateTextField(key: string, briefId?: string, initialValue?: string | null) {
    const [setText] = useBriefSetTextMutation();
    return useUpdateField({ key, setField: setText, briefId, initialValue });
}

export function useUpdateTextAreaField(
    key: string,
    briefId?: string,
    initialValue?: string | null
) {
    const [setText] = useBriefSetTextAreaMutation();
    return useUpdateField({ key, setField: setText, briefId, initialValue });
}
